import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { IconButton } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import "./styles.scss"


type Tprops = {
    open: boolean
    onClose: (open: boolean) => void
    images: any[]
    currentImage: number
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ImagerViewer: React.FC<Tprops> = ({ open, onClose, images, currentImage }) => {

    const [close, setClose] = useState<boolean>(false)
    const [index, setIndex] = useState<number>(currentImage)

    const handleOnClose = () => {
        if (close) {
            onClose(false)
        }
    }

    const handleOnIncrease = () => {
        if (!(images?.length === index)) {
            setIndex(index + 1)
        }
        if (index + 1 === images?.length) {
            setIndex(0)
        }
    }

    const handleOnDecrease = () => {
        if (index <= images?.length) {
            setIndex(index - 1)
        }
        if (index === 0) {
            setIndex(images?.length - 1)
        }
    }

    const handleOnKeyPress = (keyCode: number) => {
        if (keyCode === 37) handleOnDecrease()
        else if (keyCode === 38) handleOnDecrease()
        else if (keyCode === 39) handleOnIncrease()
        else if (keyCode === 40) handleOnIncrease()
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            className="image-viewer"
            aria-labelledby="responsive-dialog-title"
            onKeyDown={(e: any) => handleOnKeyPress(e.keyCode)}
            onClick={() => handleOnClose()}
        >
            <div className="image-container" onMouseLeave={() => setClose(true)} onMouseEnter={() => setClose(false)}>
                <div className="closeBtn" onClick={() => onClose(false)}>
                    <IconButton><ClearSharpIcon /></IconButton>
                </div>
                <IconButton onClick={() => handleOnDecrease()} className="leftArrow"><ArrowCircleLeftOutlinedIcon style={{ color: "#fff", fontSize: "50px" }} /></IconButton>
                <img src={images[index]} alt="trident" />
                <IconButton onClick={() => handleOnIncrease()} className="rightArrow"><ArrowCircleRightOutlinedIcon style={{ color: "#fff", fontSize: "50px" }} /></IconButton>
            </div>
        </Dialog>
    );
}
