export const INSTAGRAM_LINK = `https://www.instagram.com/trident._.builders/`
export const EMAIL_LINK = `mailto:tridentbuildersinfo@gmail.com`
export const PHONE_LINK = `tel:8056158200`
export const EMAIL = `tridentbuildersinfo@gmail.com`
export const navBarMenu = [{ label: "Projects", route: "/projects" }, { label: "Services", route: "/services" }, { label: "Contact", route: "/contact-us" }, { label: "About", route: "/about" }, {}]

export const downloadDocs = async (url: string) => {
	var link = document.createElement("a");
	link.target = "_blank";
	link.href = url;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

export const STATUS = {
	SUCCESS: 'success',
	FAILURE: 'failure',
	WARNING: "warning"
}

export const removeFalsy = (obj: any, deleteValue?: any[]) => {
	// remove the falsy key value pair in the object based on the values.
	const falsyArray: any = ["", null, undefined];
	const deleteArray: any = deleteValue?.concat(falsyArray)
	Object?.keys(obj).map(x => {
		if ((deleteValue?.length ? deleteArray : falsyArray).includes(obj[x])) delete obj[x];
		// if(Array.isArray(x) && x?.length === 0 ) delete obj[x];
	})
	return obj;
}

export const removeByKey = (obj: any, keyName: string[]) => {
	// remove the falsy key value pair in the object based on the keyname.
	keyName.map((keyName: string) => delete obj[keyName])
	return obj;
}

export const scrollTop = () => {
	window.scrollTo({
		top: 0,
		behavior: "smooth"
	})
}
