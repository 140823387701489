import React from "react"

type Tprops = {

}

export const TridentLogo: React.FC<Tprops> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="238" height="179.713" viewBox="0 0 238 179.713">
            <defs>
                <style>
                    {`.cls-1 {
        font-size: 30px;
        font-family: TimesNewRomanPS-BoldMT, Times New Roman;
        font-weight: 700;
         stroke:"#fff";
      }

      .cls-2 {
        font-size: 23px;
        stroke:"#fff";
      }

      .cls-3 {
        fill: none;
        stroke: #ffb501;
        stroke-width: 2.5px;
      }

      .cls-4 {
        fill: #ffb501;
      }`}
                </style>
            </defs>
            <g id="Logo_with_Name" data-name="Logo with Name" transform="translate(-3186 -210.287)">
                <text id="TRIDENT_BUILDERS" data-name="TRIDENT BUILDERS" className="cls-1" transform="translate(3186 384)"><tspan x="0" y="0">T</tspan><tspan className="cls-2" y="0">RIDENT </tspan><tspan y="0">B</tspan><tspan className="cls-2" y="0">UILDERS</tspan></text>
                <g id="Group_22604" data-name="Group 22604" transform="translate(-1475.747 -10597)">
                    <g id="Group_22784" data-name="Group 22784" transform="translate(0.247 -0.077)">
                        <path id="Path_14" data-name="Path 14" className="cls-3" d="M4813.951,10883.605v-40.083l-13.8-4.828v26.671" />
                        <path id="Path_16" data-name="Path 16" className="cls-3" d="M4813.951,10881.282v-37.76l-13.8-4.828v27.209" transform="translate(-9 6)" />
                        <path id="Path_15" data-name="Path 15" className="cls-3" d="M4813.951,10886.117v-42.595l-13.8-4.828v26.671" transform="translate(9 -5)" />
                        <path id="Path_17" data-name="Path 17" className="cls-3" d="M4801.384,10871.674l-11.453-.021" />
                        <path id="Path_18" data-name="Path 18" className="cls-3" d="M4780.5,10895.459l51.506-17.334-4.487-4.589" />
                    </g>
                    <g id="Group_22785" data-name="Group 22785" transform="translate(4728.747 10833.617)">
                        <path id="Path_14-2" data-name="Path 14" className="cls-3" d="M4800.156,10883.605v-40.083l13.8-4.828v26.671" transform="translate(-4782.101 -10833.694)" />
                        <path id="Path_16-2" data-name="Path 16" className="cls-3" d="M4800.156,10881.282v-37.76l13.8-4.828v27.209" transform="translate(-4773.101 -10827.694)" />
                        <path id="Path_15-2" data-name="Path 15" className="cls-3" d="M4800.156,10886.117v-42.595l13.8-4.828v26.671" transform="translate(-4791.101 -10838.694)" />
                        <path id="Path_17-2" data-name="Path 17" className="cls-3" d="M4789.931,10871.674l11.453-.021" transform="translate(-4759.309 -10833.694)" />
                        <path id="Path_18-2" data-name="Path 18" className="cls-3" d="M4832.006,10895.459l-51.506-17.334,4.487-4.589" transform="translate(-4780.5 -10833.694)" />
                    </g>
                    <g id="Group_22786" data-name="Group 22786" transform="translate(0.041)">
                        <line id="Line_237" data-name="Line 237" className="cls-3" y1="112" transform="translate(4780.5 10817.5)" />
                        <rect id="Rectangle_4407" data-name="Rectangle 4407" className="cls-4" width="10.186" height="10.186" transform="translate(4780.5 10928.287) rotate(45)" />
                        <path id="Path_19" data-name="Path 19" className="cls-4" d="M0,0,12.427,2.24,7.333,7.333,2.24,12.427Z" transform="translate(4780.5 10807.287) rotate(45)" />
                    </g>
                </g>
            </g>
        </svg>

    )
}