import React, { useEffect } from "react";
import Layout from "../../layout";
import "./styles.scss"
import { scrollTop } from "../../common/constants";
import { projectData } from "../../common/data";
import { ProjectCardDesign } from "../../components/CardDesign";


type Tprops = {

}

const ProjectPage: React.FC<Tprops> = () => {



    useEffect(() => {
        scrollTop()
    }, [])

    return (
        <div className="projectPage">
            <section className="sec1Background">
                <section className="sec1">
                    <div className="sec1-left">
                        <h1>
                            Projects that we work on to enlight you ...
                        </h1>
                    </div>
                </section>
            </section>
            <section className="sec2Background">
                <div className="content">
                    {projectData.map((data, index: number) => <ProjectCardDesign key={index} banner={data.banner[0]} route={`/projects/${data.id}`} heading={data.thumbnail} />)}
                </div>
            </section>
        </div>
    )
}

export default Layout(ProjectPage);