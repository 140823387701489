import Chatbot from "../components/Chatbot/bot";
import { PrivateRoutes } from "./privateRoutes";
import { PublicRoutes } from "./publicRoutes";
import "./styles.scss"

export const RootRoutes = () => {
    const token = undefined;

    return (
        <div className="pageWrapper"> 
            {token ? <PrivateRoutes /> : <PublicRoutes />}
            <Chatbot />
        </div>
    )
}
