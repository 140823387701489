import "./styles.scss"
import React, { useState } from "react";
import Chats from "../chats";
import { analyzeNextSteps } from "../helperFunction";
import EngineeringIcon from '@mui/icons-material/Engineering';
import { IconButton } from "@mui/material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SendIcon from '@mui/icons-material/Send';

interface ResponseBotObject {
  purpose: string;
  message: string;
  options?: string[];
  sender: string;
}

const Chatbot: React.FC = () => {
  const [userResponse, setUserResponse] = useState<string>("");
  const [openBot, setOpenBot] = useState<boolean>(false)
  const [step, setStep] = useState<number>(0);
  const [botResponse, setBotResponse] = useState<ResponseBotObject>({
    purpose: "",
    message: "",
    sender: "bot"
  });
  const [sendUserResponse, setSendUserResponse] = useState<string>("");

  // setting next step when there's response and option click
  const setNextStep = (response: string) => {
    setStep(prevState => prevState + 1);
    setSendUserResponse(response);
    let res = analyzeNextSteps(step, response);
    setBotResponse({ ...res, sender: "bot" });
    setUserResponse("");
  };

  const optionClick = (e: React.MouseEvent<HTMLElement>) => {
    let option = e.currentTarget.dataset.id;
    if (option) {
      setNextStep(option);
    }
  };

  // event handlers
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserResponse(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setNextStep(userResponse);
  };


  return (
    <div className="chat">
      <div className="chat-wrapper">
        {openBot && <div className="chat-container">
          <Chats
            userResponse={userResponse}
            botResponse={botResponse}
            sendUserResponse={sendUserResponse}
            optionClick={optionClick}
          />
          <form onSubmit={e => handleSubmit(e)} className="form-container">
            <input
              placeholder="Type your message..."
              onChange={e => handleInputChange(e)}
              value={userResponse}
            />
            <button>
              <SendIcon />
            </button>
          </form>
        </div>}
        {/* <IconButton onClick={() => setOpenBot(!openBot)}
          className="icon">
          <EngineeringIcon />
        </IconButton> */}
        <a href={`https://api.whatsapp.com/send?phone=9840376116`} target="_blank" rel="noreferrer">
          <IconButton
            className="icon">
            <WhatsAppIcon />
          </IconButton>
        </a>
      </div>
    </div>
  );
};

export default Chatbot;
