import Project1Img1 from "../assests/images/project1/1.jpg"
import Project1Img2 from "../assests/images/project1/2.jpg"
import Project1Img3 from "../assests/images/project1/3.jpg"
import Project1Img4 from "../assests/images/project1/4.jpg"
import Project1Img5 from "../assests/images/project1/5.jpg"
import Project1Img6 from "../assests/images/project1/6.jpg"
import Project2Img1 from "../assests/images/project2/1.jpg"
import Project2Img2 from "../assests/images/project2/2.jpg"
import Project2Img3 from "../assests/images/project2/3.jpg"
import Project2Img4 from "../assests/images/project2/4.jpg"
import Project2Img5 from "../assests/images/project2/5.jpg"
import Project2Img6 from "../assests/images/project2/6.jpg"
import Project2Img7 from "../assests/images/project2/7.jpg"
import Project2Img8 from "../assests/images/project2/8.jpg"
import Project2Img9 from "../assests/images/project2/9.jpg"
import Project2Img10 from "../assests/images/project2/10.jpg"
import Project2Img11 from "../assests/images/project2/11.jpg"
import Project3Img1 from "../assests/images/project3/1.jpeg"
import Project3Img2 from "../assests/images/project3/2.jpeg"
import Project3Img3 from "../assests/images/project3/3.jpeg"
import Project3Img4 from "../assests/images/project3/4.jpeg"
import Project3Img5 from "../assests/images/project3/5.jpeg"
import Project3Img6 from "../assests/images/project3/6.jpeg"
import Project3Img7 from "../assests/images/project3/7.jpeg"
import Project3Img8 from "../assests/images/project3/8.jpeg"
import Project3Img9 from "../assests/images/project3/9.jpeg"
import Project3Img10 from "../assests/images/project3/10.jpeg"
import Project3Img11 from "../assests/images/project3/11.jpeg"

import Project4Img1 from "../assests/images/project4/1.jpeg"
import Project4Img2 from "../assests/images/project4/2.jpeg"
import Project4Img3 from "../assests/images/project4/3.jpeg"
import Project4Img4 from "../assests/images/project4/4.jpeg"
import Project4Img5 from "../assests/images/project4/5.jpeg"
import Project4Img6 from "../assests/images/project4/6.jpeg"
import Project4Img7 from "../assests/images/project4/7.jpeg"
import Project4Img8 from "../assests/images/project4/8.jpeg"
import Project4Img9 from "../assests/images/project4/9.jpeg"
import Project4Img10 from "../assests/images/project4/10.jpeg"
import Project4Img11 from "../assests/images/project4/11.jpeg"
import Project4Img12 from "../assests/images/project4/12.jpeg"
import Project4Img13 from "../assests/images/project4/13.jpeg"
import Project4Img14 from "../assests/images/project4/14.jpeg"
import Project4Img15 from "../assests/images/project4/15.jpeg"
import Project4Img16 from "../assests/images/project4/16.jpeg"
import Project4Img17 from "../assests/images/project4/17.jpeg"
import Project4Img18 from "../assests/images/project4/18.jpeg"
import Project4Img19 from "../assests/images/project4/19.jpeg"
import Project4Img20 from "../assests/images/project4/20.jpeg"
import Project4Img21 from "../assests/images/project4/21.jpeg"
import Project4Img22 from "../assests/images/project4/22.jpeg"
import Project4Img23 from "../assests/images/project4/23.jpeg"

import Project5Img1 from "../assests/images/project5/1.jpeg"
import Project5Img2 from "../assests/images/project5/2.jpeg"
import Project5Img3 from "../assests/images/project5/3.jpeg"
import Project5Img4 from "../assests/images/project5/4.jpeg"
import Project5Img5 from "../assests/images/project5/5.jpeg"
import Project5Img6 from "../assests/images/project5/6.jpeg"
import Project5Img7 from "../assests/images/project5/7.jpeg"
import Project5Img8 from "../assests/images/project5/8.jpeg"
import Project5Img9 from "../assests/images/project5/9.jpeg"
import Project5Img10 from "../assests/images/project5/10.jpeg"

import LRJ from "../assests/images/aboutUs/DrLRJ.jpeg"


export const projectData = [
    {
        id: 1,
        banner: [Project1Img1, Project1Img2, Project1Img3, Project1Img4, Project1Img5, Project1Img6],
        thumbnail: "BRG Nivas",
        heading: "Individual bungalow Architecture Design",
        location: "Adambakkam - Chennai",
        status: "Handed-Over",
        client: "Ganeshram",
        type: "Residence",
        programme: "Residence Architecture",
        description: `This is a 7500 sq ft contemporary style individual house located in Adambakkam . The site located in a south-west corner, bounded by roads on two sides. We came up with a plan leaving out the ground floor area for rent and keeping the first and second floor areas interconnected to form a duplex. The client wished for the spaces in his home to be transformable to accommodate the families of both his daughters in the future, forming two split levels of the now clubbed duplex. The transformation would include extending the stairway in the front to the second floor forming separate entries and modify the lift duct to form a courtyard. There is an office space provided on the first floor which will later be fused with the living area. Spaces are defined with respect to Vastu, and open spaces are provided promoting cross ventilation. The elevation splits into different features.  There is a projection all around the building in the second-floor level.That, along with circular column support, texture finishes, pergolas and contemporary grill designs add up to enhance the outlook of the building.`,
        ratings: 3,
        review: "Working with Trident Builder’s engineers has been an extremely efficacious process for me. The answers are timely, explanations are never rushed, and communications are consistently clear and concise."
    },
    {
        id: 2,
        banner: [Project2Img1, Project2Img2, Project2Img3, Project2Img4, Project2Img5, Project2Img6, Project2Img7, Project2Img8, Project2Img9, Project2Img10, Project2Img11],
        thumbnail: "Airwell house",
        heading: "Individual bungalow Architecture Design",
        location: "Gerugambakkam - Chennai",
        status: "Handed-Over",
        type: "Residence",
        client: "Rajesh",
        programme: "Residential Architecture",
        description: `This is a 7500 sq ft contemporary style individual house located in Gergumbakkam. The site located in a south-west corner, bounded by roads on two sides. We came up with a plan leaving out the ground floor area for rent and keeping the first and second floor areas interconnected to form a duplex. The client wished for the spaces in his home to be transformable to accommodate the families of both his daughters in the future, forming two split levels of the now clubbed duplex. The transformation would include extending the stairway in the front to the second floor forming separate entries and modify the lift duct to form a courtyard. There is an office space provided on the first floor which will later be fused with the living area. Spaces are defined with respect to Vastu, and open spaces are provided promoting cross ventilation. The elevation splits into different features.  There is a projection all around the building in the second-floor level.That, along with circular column support, texture finishes, pergolas and contemporary grill designs add up to enhance the outlook of the building.`,
        ratings: 3,
        review: " Working with Trident Builder’s engineers has been an extremely efficacious process for me. The answers are timely, explanations are never rushed, and communications are consistently clear and concise."
    },
    {
        id: 3,
        banner: [Project3Img1, Project3Img2, Project3Img3, Project3Img4, Project3Img5, Project3Img6, Project3Img7, Project3Img8, Project3Img9, Project3Img10, Project3Img11],
        thumbnail: "Sunland IT Solutions",
        heading: "Interior Architecture Design",
        location: "Kodambakkam - Chennai",
        status: "Handed-Over",
        type: "Residence",
        client: "Sunland",
        programme: "Interior Architecture Design",
        description: `This is a 7500 sq ft contemporary style individual house located in Gergumbakkam. The site located in a south-west corner, bounded by roads on two sides. We came up with a plan leaving out the ground floor area for rent and keeping the first and second floor areas interconnected to form a duplex. The client wished for the spaces in his home to be transformable to accommodate the families of both his daughters in the future, forming two split levels of the now clubbed duplex. The transformation would include extending the stairway in the front to the second floor forming separate entries and modify the lift duct to form a courtyard. There is an office space provided on the first floor which will later be fused with the living area. Spaces are defined with respect to Vastu, and open spaces are provided promoting cross ventilation. The elevation splits into different features.  There is a projection all around the building in the second-floor level.That, along with circular column support, texture finishes, pergolas and contemporary grill designs add up to enhance the outlook of the building.`,
        ratings: 3,
        review: " Working with Trident Builder’s engineers has been an extremely efficacious process for me. The answers are timely, explanations are never rushed, and communications are consistently clear and concise."
    },
    {
        id: 4,
        banner: [Project4Img1, Project4Img2, Project4Img3, Project4Img4, Project4Img5, Project4Img6, Project4Img7, Project4Img8, Project4Img9, Project4Img10, Project4Img11, Project4Img12, Project4Img13, Project4Img14, Project4Img15, Project4Img16, Project4Img17, Project4Img18, Project4Img19, Project4Img20, Project4Img21, Project4Img22, Project4Img23],
        thumbnail: "Office Interior",
        heading: "Interior Architecture Design",
        location: "Nungambakkam - Chennai",
        status: "Handed-Over",
        type: "Residence",
        client: "Client Name",
        programme: "Interior Architecture Design",
        description: `This is a 7500 sq ft contemporary style individual house located in Gergumbakkam. The site located in a south-west corner, bounded by roads on two sides. We came up with a plan leaving out the ground floor area for rent and keeping the first and second floor areas interconnected to form a duplex. The client wished for the spaces in his home to be transformable to accommodate the families of both his daughters in the future, forming two split levels of the now clubbed duplex. The transformation would include extending the stairway in the front to the second floor forming separate entries and modify the lift duct to form a courtyard. There is an office space provided on the first floor which will later be fused with the living area. Spaces are defined with respect to Vastu, and open spaces are provided promoting cross ventilation. The elevation splits into different features.  There is a projection all around the building in the second-floor level.That, along with circular column support, texture finishes, pergolas and contemporary grill designs add up to enhance the outlook of the building.`,
        ratings: 3,
        review: " Working with Trident Builder’s engineers has been an extremely efficacious process for me. The answers are timely, explanations are never rushed, and communications are consistently clear and concise."
    },
    {
        id: 5,
        banner: [Project5Img1, Project5Img2, Project5Img3, Project5Img4, Project5Img5, Project5Img6, Project5Img7, Project5Img8, Project5Img9, Project5Img10],
        thumbnail: "Fight to be fit",
        heading: "Interior Architecture Design",
        location: "Kodambakkam - Chennai",
        status: "Handed-Over",
        type: "Gym",
        client: "Fight to be fit",
        programme: "Interior Gym Design",
        description: `This is a 7500 sq ft contemporary style individual house located in Gergumbakkam. The site located in a south-west corner, bounded by roads on two sides. We came up with a plan leaving out the ground floor area for rent and keeping the first and second floor areas interconnected to form a duplex. The client wished for the spaces in his home to be transformable to accommodate the families of both his daughters in the future, forming two split levels of the now clubbed duplex. The transformation would include extending the stairway in the front to the second floor forming separate entries and modify the lift duct to form a courtyard. There is an office space provided on the first floor which will later be fused with the living area. Spaces are defined with respect to Vastu, and open spaces are provided promoting cross ventilation. The elevation splits into different features.  There is a projection all around the building in the second-floor level.That, along with circular column support, texture finishes, pergolas and contemporary grill designs add up to enhance the outlook of the building.`,
        ratings: 3,
        review: " Working with Trident Builder’s engineers has been an extremely efficacious process for me. The answers are timely, explanations are never rushed, and communications are consistently clear and concise."
    }

    // { id: 2, banner: [Project2Img1], heading: "Complete Rennovation" },
    // { id: 3, banner: [Project3Img1], heading: "Complete Rennovation" },
    // { id: 4, banner: [Project4Img1], heading: "Complete Rennovation" },
    // { id: 5, banner: [Project5Img1], heading: "Complete Rennovation" },
    // { id: 6, banner: [Project6Img1], heading: "Complete Rennovation" },
    // { id: 7, banner: [Project7Img1], heading: "Complete Rennovation" },
    // { id: 8, banner: [Project8Img1], heading: "Complete Rennovation" },
    // { id: 9, banner: [Project9Img1], heading: "Complete Rennovation" },
    // { id: 10, banner: [Project10Img1], heading: "Complete Rennovation" },
    // { id: 11, banner: [Project11Img1], heading: "Complete Rennovation" },
    // { id: 12, banner: [Project12Img1], heading: "Complete Rennovation" },
    // { id: 13, banner: [Project13Img1], heading: "Complete Rennovation" },
    // { id: 14, banner: [Project14Img1], heading: "Complete Rennovation" },
    // { id: 15, banner: [Project15Img1], heading: "Complete Rennovation" },
    // { id: 16, banner: [Project16Img1], heading: "Complete Rennovation" },
    // { id: 17, banner: [Project17Img1], heading: "Complete Rennovation" },
    // { id: 18, banner: [Project18Img1], heading: "Complete Rennovation" },
]

export const aboutUsData = [{ image: LRJ, name: "Dr.L Ramajeyam", designation: "Founder & Chief Structural Engineer" }]