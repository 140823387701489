import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Logo from "../../assests/icons/logo.svg"
import MenuIcon from '@mui/icons-material/Menu';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import InfoIcon from '@mui/icons-material/Info';
import "./styles.scss"
import { EMAIL, EMAIL_LINK, INSTAGRAM_LINK, navBarMenu, PHONE_LINK } from "../../common/constants";
import { TridentLogo } from "../../components/icons/logo";

type Tprops = {

}

export const Header: React.FC<Tprops> = () => {

    const location = useLocation()
    const [isActive, setIsActive] = useState<boolean>(false)
    const icons = [<DesignServicesIcon />, <MiscellaneousServicesIcon />, <ContactSupportIcon />, <InfoIcon />]

    window.onscroll = function () {
        if (window.pageYOffset > 100) {
            document.querySelector(".header")?.classList.add("active")
        } else {
            document.querySelector(".header")?.classList.remove("active")
        }
    }

    return (
        <header className="header">
            <div className="header-container">
                <div className="logo-container">
                    <a href="/">
                        <TridentLogo />
                    </a>
                </div>
                <div className="nav-bar-container">
                    {navBarMenu.map((data, index: number) => <span key={index} className="nav-links">
                        <Link to={`${data.route}`} style={location.pathname === data.route ? { textDecoration: "underline" } : {}}>{data.label}</Link>
                    </span>)}
                </div>
                <div className="social-media-share">
                    <a className="icon" href={EMAIL_LINK} target="_blank" aria-label={EMAIL}>
                        <EmailIcon style={{ color: "#fff" }} />
                    </a>
                    <a className="icon" href={INSTAGRAM_LINK} target="_blank" >
                        <InstagramIcon style={{ color: "#fff" }} />
                    </a>
                    <a className="icon" href={PHONE_LINK} target="_blank">
                        <LocalPhoneIcon style={{ color: "#fff" }} />
                    </a>
                </div>
                <div className="menuContainer">
                    <MenuIcon className="menuTrigger" onClick={() => setIsActive(!isActive)} />
                    {isActive && <div className="overlay" onClick={() => setIsActive(false)}></div>}
                    <nav className={`popupMenu ${isActive ? "active" : ""}`}>
                        <ul>
                            {navBarMenu.map((data, index: number) => <li key={index}>
                                {icons[index]}
                                <span className="nav-links">
                                    <Link to={`${data.route}`} style={location.pathname === data.route ? { textDecoration: "underline" } : {}}>{data.label}</Link>
                                </span></li>)}
                            <li>
                                <a className="icon" href={EMAIL_LINK} target="_blank" aria-label={EMAIL}>
                                    <EmailIcon style={{ color: "#fff" }} />
                                </a>
                                <a className="icon" href={INSTAGRAM_LINK} target="_blank" >
                                    <InstagramIcon style={{ color: "#fff" }} />
                                </a>
                                <a className="icon" href={PHONE_LINK} target="_blank">
                                    <LocalPhoneIcon style={{ color: "#fff" }} />
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    )
}