import { table } from "console";
import React, { useState, useEffect } from "react";
import { STATUS } from "../../common/constants";
import { IGetEnquiry } from "../../interface/enquiry.interface";
import { EnquiryService } from "../../services/enquiry.service";

type Tprops = {

}


const Dashboard: React.FC<Tprops> = () => {

    const [allEnquiries, setAllEnquiries] = useState<IGetEnquiry[]>([])
    const enquiryService = new EnquiryService()
    console.log({ allEnquiries });


    const fetchEnquiryData = async () => {
        try {
            const getAllEnquiry = await enquiryService.getAllEnquiry()
            if (getAllEnquiry.status === STATUS.SUCCESS) {
                setAllEnquiries(getAllEnquiry.data)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchEnquiryData()
    }, [])


    return (
        <div>
            Dashboard page
            <table>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Date</th>
                        <th>Interest</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {allEnquiries.map((enquiry: IGetEnquiry, index: number) => <tr key={index}>
                        <td><p>{index + 1}</p></td>
                        <td><p>{enquiry.name}</p></td>
                        <td><p>{enquiry.mobile}</p></td>
                        <td><p>{enquiry.email}</p></td>
                        <td><p>{enquiry.createdAt}</p></td>
                        <td><p>{enquiry.intrests}</p></td>
                        <td><textarea value={enquiry.description} disabled /></td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}

export default Dashboard;