import React, { useEffect } from "react";
import { scrollTop } from "../../common/constants";
import { aboutUsData } from "../../common/data";
import UserImageCard from "../../components/UserImageCard";
import Layout from "../../layout";
import "./styles.scss"

type Tprops = {

}

const AboutPage: React.FC<Tprops> = () => {

    useEffect(() => {
        scrollTop()
    }, [])

    return (
        <div className="about">
            <section className="sec1Background">
                <section className="sec1">
                    <div className="sec1-left">
                        <h1>
                            About Us !
                        </h1>
                    </div>
                </section>
            </section>
            <section className="sec2Background" style={{ paddingTop: "3.6vmax" }}>
                <h6 className="heading">We are your full-service interior design agency.</h6>
                <div className="description-wrapper">
                    <p>We have interior architects and project managers who take the idea all the way to the finished result. Our proposals are adapted to each customer's unique needs and often consist of drawings, design concepts, interior design proposals and project management, parts that together take our customers' homes to a whole new level.</p>
                </div>
            </section>
            <section className="sec2Background">
                <h6 className="heading">Comprehensive solutions.</h6>
                <div className="description-wrapper">
                    <p>We work with interior design and planning in all its stages. With us, you can get help with comprehensive solutions where we deliver all stages from drawing to results. We also offer tailored advice and workshops.</p>
                </div>
            </section>

            <section className="sec3Background">
                <h1 className="heading">Our Team</h1>
                <div className="description-wrapper">
                    <p>We have interior architects and project managers who take the idea all the way to the finished result. Our proposals are adapted to each customer's unique needs and often consist of drawings, design concepts, interior design proposals and project management, parts that together take our customers' homes to a whole new level.</p>
                </div>
                <div className="imageContainer">
                    {aboutUsData?.map((data, index) => <UserImageCard key={index} image={data?.image} name={data?.name} designation={data?.designation} />)}
                </div>
            </section>
            <section className="sec2Background mt50">
                <h6 className="heading">A team with many different skills.</h6>
                <div className="description-wrapper">
                    <p>Our team consists of interior architects and project managers who, together with a network of craftsmen, furnish and renovate environments where no project is the same. We are driven by exceeding our customers' expectations and for us the most important thing is that each customer feels unique, receives the focus and help that is expected and that the result of the project exceeds expectations! A satisfied customer is the best result and we have succeeded in a good job when a customer returns to us for more help.</p>
                </div>
            </section>
        </div>
    )
}

export default Layout(AboutPage);