import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { STATUS } from '../common/constants'
import { IErrorResponse } from '../interface/baseInterface'
export const BASE_URL = "http://13.127.241.148/api/api"
// export const BASE_URL = http://localhost:8080/api


export const parseAxiosError = (error: AxiosError): IErrorResponse => {
	if (error.isAxiosError && error.response) {
		return { status: STATUS.FAILURE, message: error.response.data.message, error }
	}
	return { status: STATUS.FAILURE, message: error.response?.data.message, error }
}
export class BaseService {

    httpClient: AxiosInstance = axios.create({ baseURL: BASE_URL });

    constructor() {

        this.httpClient.interceptors.request.use((config: AxiosRequestConfig) => {
            if (!config.headers?.authorization) {
                //@ts-ignore 
                config.headers['Authorization'] = `Bearer ${localStorage.getItem("accessToken")}`;
            }
            return config
        });
    }
}