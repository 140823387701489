// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { scrollTop } from "../../../common/constants";
import { projectData } from "../../../common/data";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonOutlineSharpIcon from '@mui/icons-material/PersonOutlineSharp';
import MapsHomeWorkSharpIcon from '@mui/icons-material/MapsHomeWorkSharp';
import AccessTimeSharpIcon from '@mui/icons-material/AccessTimeSharp';
import Layout from "../../../layout";
import "./styles.scss"
import { ImagerViewer } from "../../../components/ImageViewer";


type Tprops = {

}

const ProjectDetailsPage: React.FC<Tprops> = () => {

    const params = useParams()
    const [data, setData] = useState<any>()
    const [openViewer, setOpenViewer] = useState<boolean>(false)
    const [index, setIndex] = useState<number>(0)
    

    const myStyle = {
        backgroundImage:
            `url(${data?.banner[0]})`,
    };

    const getData = (id: number) => {
        const a = projectData?.find((val) => val.id === id)
        setData(a)
    }

    useEffect(() => {
        scrollTop()
        getData(Number(params.id))
    }, [])

    return (
        <div className="projectDetailsPage">
            <section className="sec1Background" style={myStyle}>
                <section className="sec1">
                    <div className="sec1-left">
                        <h1>{data?.client} {data?.type}</h1>
                        <h6>@{data?.location}</h6>
                    </div>
                </section>
            </section>
            <section className="sec2Background">
                <h1 className="heading">PROJECT OVERVIEW</h1>
                <div className="overview-wrapper">
                    <div className="element">
                        <LocationOnIcon />
                        <h1>LOCATION</h1>
                        <h6>{data?.location}</h6>
                    </div>
                    <div className="element">
                        <PersonOutlineSharpIcon />
                        <h1>CLIENT</h1>
                        <h6>{data?.client}</h6>
                    </div>
                    <div className="element">
                        <MapsHomeWorkSharpIcon />
                        <h1>PROGRAMME</h1>
                        <h6>{data?.programme}</h6>
                    </div>
                    <div className="element">
                        <AccessTimeSharpIcon />
                        <h1>STATUS</h1>
                        <h6>{data?.status}</h6>
                    </div>
                </div>
                <div className="description-wrapper">
                    <p>{data?.description}</p>
                </div>
            </section>
            <section className="sec3Background">
                <div className="imageContainer">
                    {data?.banner?.map((val: any, index: number) => <div className="item" onClick={() => { setOpenViewer(true); setIndex(index) }}><img src={val} alt="" /></div>)}
                </div>
            </section>
            <section className="sec4Background">
                <h1 className="heading">REVIEWS</h1>
                <div className="sec4">
                    {/* <div className="profilePics">
                        <img src={data?.banner[0]} alt="" />
                    </div> */}
                    <div className="reviewArea">
                        <p>{data?.review}</p>
                        <h1>- {data?.client}</h1>
                    </div>
                </div>
            </section>
            {openViewer && <ImagerViewer open={openViewer} onClose={() => setOpenViewer(false)} images={data.banner} currentImage={index} />}
        </div>
    )
}

export default Layout(ProjectDetailsPage);