import React from "react"
import "./styles.scss"

type Tprops = {

    image: any,
    designation: string
    name: string

} & React.DOMAttributes<HTMLDivElement>

const UserImageCard: React.FC<Tprops> = ({ image, designation, name, ...props }) => {
    return (
        <div className="userImageCard" {...props}>
            <img src={image} />
            <h6 className="name">{name}</h6>
            <p className="designation">{designation}</p>
        </div>
    )
}

export default UserImageCard;