import React, { useEffect } from "react";
import { EMAIL, EMAIL_LINK, scrollTop, STATUS } from "../../common/constants";
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Layout from "../../layout";
import * as Yup from 'yup'
import "./styles.scss"
import { useFormik } from "formik";
import { emailRegex } from "../../common/regexPattern";
import TextBox from "../../components/TextField";
import RadioGroup from "../../components/RadioGroup";
import { Button } from "../../components/Button";
import CheckBox from "../../components/CheckBox";
import { EnquiryService } from "../../services/enquiry.service";
import Chatbot from "../../components/Chatbot/bot";

type Tprops = {

}

const ContactUs: React.FC<Tprops> = () => {

    const enquiryService = new EnquiryService()

    const initialFormValues = {
        name: "",
        mobile: "",
        email: "",
        intrests: "",
        description: "",
        isTermAccepted: false
    }

    const contactUsValidations = Yup.object().shape({
        name: Yup.string().trim().required('Please enter your name').max(30, "Name must be maximum 30 characters").min(3, "Name must be minimum 3 characters "),
        email: Yup.string().trim().matches(emailRegex, "Please enter valid email").max(100, "email shoul not exeed 100 characters").required("Please provide email"),
        mobile: Yup.string().trim().required('Please enter mobile number').max(10, "Please enter valid number ").min(10, "Please enter valid number ").required("Please provide mobile number"),
        intrests: Yup.string().trim().required("Please select Interests"),
        description: Yup.string().trim().required("Please provide Description"),
        isTermAccepted: Yup.boolean().oneOf([true], 'Kindly Accept terms of use and Privacy policy.')
    })


    const handleSubmit = async (values: any) => {
        try {
            delete values["isTermAccepted"]
            const addEnquiry = await enquiryService.addNewEnquiry(values)
            if (addEnquiry.status === STATUS.SUCCESS) {
                contactUsForm.resetForm()
            }
        }
        catch (err: any) {
        }
    }

    const contactUsForm = useFormik({ initialValues: initialFormValues, validationSchema: contactUsValidations, onSubmit: handleSubmit })

    useEffect(() => {
        scrollTop()
    }, [])

    return (
        <div className="contactUs">
            <section className="sec1Background">
                <section className="sec1">
                    <div className="sec1-left">
                        <h1>
                            Contact Us !
                        </h1>
                    </div>
                </section>
            </section>
            <section className="sec2Background">
                <div className="addressArea">
                    <h1>Trident Builders</h1>
                    <div className="contact-wrapper">
                        <span><LocationOnIcon /> 23/8, Moovendhar flats, <br />Muthalamman koil Street,<br /> West mambalam, Chennai - 600 033.</span>
                        <span><LocalPhoneIcon /> +91 80561 58200 <br /> +91 98403 76116, <br /> +91 98410 97110 </span>
                        <a href={EMAIL_LINK} target="_blank" aria-label={EMAIL}><span><EmailIcon />{EMAIL}</span></a>
                    </div>
                </div>
            </section>
            <section className="sec3Background">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d971.7567719137295!2d80.21774182922968!3d13.033947005979378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52671d783c4fc3%3A0xd3eeaeb7bd38d35a!2sM-23%2F7%2C%20Muthalamman%20Koil%20St%2C%20Moovendar%20Colony%2C%20West%20Mambalam%2C%20Chennai%2C%20Tamil%20Nadu%20600033!5e0!3m2!1sen!2sin!4v1667011321713!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    style={{ "border": 0 }}
                    allowFullScreen={true}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade" />
            </section>
            <section className="sec4Background">
                {/* <form onSubmit={contactUsForm.handleSubmit} className="form-container">
                    <TextBox label="Name" placeholder="Your name" formik={{ ...contactUsForm.getFieldProps("name") }} required errorText={contactUsForm.errors.name} error={contactUsForm?.touched?.name} />
                    <TextBox label="Email" placeholder="Your email" {...contactUsForm.getFieldProps("email")} required errorText={contactUsForm.errors.email} error={contactUsForm?.touched?.email} />
                    <TextBox label="Mobile" placeholder="Your mobile" type={"text"} {...contactUsForm.getFieldProps("mobile")} required errorText={contactUsForm.errors.mobile} error={contactUsForm?.touched?.mobile} />
                    <RadioGroup
                        errorText={contactUsForm.errors.intrests}
                        error={contactUsForm?.touched?.intrests}
                        required
                        label={"Your Interests"}
                        radioElements={["Turnkey Projects", "Structural Design", "Architectural Floor Plan", "Interior Design", "Kitchen Design", "Corporate Interior Design", "Site-built Furniture"]}
                        value={(intrests: any) => contactUsForm.setFieldValue("intrests", intrests)} />
                    <TextBox label="Description" placeholder="Tell us more about your what can we help you with..." textArea {...contactUsForm.getFieldProps("description")} required errorText={contactUsForm.errors.description} error={contactUsForm?.touched?.description} />
                    <CheckBox
                        label="I consent to the processing of my personal data in accordance with our privacy policy."
                        formik={{ ...contactUsForm.getFieldProps("isTermAccepted") }}
                        errorText={contactUsForm.errors.isTermAccepted}
                        error={contactUsForm?.touched?.isTermAccepted}
                    />
                    <button className="send-btn">Send &#8594;</button>
                </form> */}
            </section>
        </div>
    )
}

export default Layout(ContactUs);