import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "./styles.scss"
import { scrollTop } from "../../common/constants";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { IconButton } from "@mui/material";
import KitchenBanner from "../../assests/images/services/kitchen-design.jpg"
import BathroomBanner from "../../assests/images/services/bathroom-design.jpg"
import SiteBuiltFurniture from "../../assests/images/services/sitebuiltfurniture-design.jpg"
import PlanSolutionChange from "../../assests/images/services/plan-solution-change.jpg"
import ProjectManagement from "../../assests/images/services/project-management.jpg"
import FurnishingConcept from "../../assests/images/services/bathroom-design.jpg"
import Consultation from "../../assests/images/services/consultation.jpg"
import { ProjectCardDesign } from "../../components/CardDesign";

type Tprops = {

}

const ServicePage: React.FC<Tprops> = () => {

    const [activeIndex, setActiveIndex] = useState<number>(0)
    const content = ["Let our interior designers find your style ...", "Easier and safer with project management ...", "Full-service interior design agency ..."]
    const servicesList = [
        { banner: KitchenBanner, route: "/", heading: "Turnkey Projects" },
        { banner: BathroomBanner, route: "/", heading: "Structural Design" },
        { banner: SiteBuiltFurniture, route: "/", heading: "Architectural Floor Plan" },
        { banner: PlanSolutionChange, route: "/", heading: "Interior Design" },
        { banner: ProjectManagement, route: "/", heading: "Kitchen Design" },
        { banner: FurnishingConcept, route: "/", heading: "Corporate Interior Design" },
        { banner: Consultation, route: "/", heading: "Site-built Furniture" },
    ]

    const handleOnIncrease = () => {
        if (activeIndex === 2) {
            setActiveIndex(0)
        }
        else {
            setActiveIndex(activeIndex + 1)
        }
    }

    const handleOnDecrease = () => {
        if (activeIndex === 0) {
            setActiveIndex(2)
        }
        else {
            setActiveIndex(activeIndex - 1)
        }
    }

    useEffect(() => {
        scrollTop()
    }, [])



    return (
        <div className="servicePage">
            <section className={`sec1Background bg${activeIndex}`}>
                <section className="sec1">
                    <div className="arrows">
                        <IconButton onClick={() => handleOnDecrease()}><ArrowCircleLeftOutlinedIcon style={{ color: "#fff", fontSize: "50px" }} /></IconButton>
                    </div>
                    <div className="sec1-left">
                        <h1>
                            {content[activeIndex]}
                        </h1>
                    </div>
                    <div className="arrows">
                        <IconButton onClick={() => handleOnIncrease()}><ArrowCircleRightOutlinedIcon style={{ color: "#fff", fontSize: "50px" }} /></IconButton>
                    </div>
                </section>
            </section>
            <section className="sec2Background">
                <div className="content">
                    {servicesList.map((data) => <ProjectCardDesign banner={data.banner} route={data.route} heading={data.heading} />)}
                </div>
            </section>
        </div>
    )
}

export default Layout(ServicePage);