import React from "react"
import { Link } from "react-router-dom"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import "./styles.scss"

type Tprops = {
    banner: any
    route: string
    heading: string
} & React.DOMAttributes<HTMLDivElement>

export const ProjectCardDesign: React.FC<Tprops> = ({ banner, route, heading, ...props }) => {
    return (
        <div className="cardDesign" {...props}>
            <Link to={`${route}`}>
                <img src={banner} />
                <div className="content">
                    <h3 className="title">{heading}</h3>
                </div>
            </Link>
        </div>
    )
}