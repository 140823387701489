import { AxiosError } from "axios";
import { ISuccessResponse, IErrorResponse } from "../interface/baseInterface";
import { INewEnquiry } from "../interface/enquiry.interface";
import { API_END_POINTS } from "./apiEndPoints";
import { parseAxiosError } from "./baseService";
import { BaseService } from "./baseService";

export class EnquiryService extends BaseService {

    public async addNewEnquiry(value: INewEnquiry): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_END_POINTS.ADD_ENQUIRY, value);
            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async getAllEnquiry(): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get(API_END_POINTS.GET_ALL_ENQUIRY);
            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }
}